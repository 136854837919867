import { IMAGE_DEPOIMENTO } from "../../../images";
import { BackGroundDepoimentos } from "./styles";
import { useQuery } from "react-query";
import useApi from "../../../hooks/useApi";
import React, { FC } from "react";
import { Card, Carousel, CarouselItem } from "react-bootstrap";
import { useParams } from "react-router-dom";

type IParams = {
    departamentos: string
}

interface PropsDepoimentoI {
    nome: string
    descricao: string
    usuario: string
}

const Depoimento: FC<PropsDepoimentoI> = ({ nome, usuario, descricao }) => {
    let user = usuario.split(' ');
    let userLast = user.length;
    userLast = userLast - 1;
    return (
        <div style={{ width: '750px', height: 'auto', position: "relative", top: '-8%', left: '15%' }}>
            <h1 style={{ color: '#183A6A', fontSize: '2.3rem', margin: 0, padding: 0, fontWeight: '700', fontStyle: 'italic' }}>{`Parabéns, ${user[0]} ${user[userLast] ? user[userLast] : ''}!`}</h1>
            <p style={{ color: '#183A6A', fontSize: '2.25rem', font: 'caption', marginTop: '7px', fontStyle: 'italic' }}>"{descricao.toUpperCase()}"</p>
            <p style={{ color: '#183A6A', fontSize: '1.8rem', fontWeight: 'bold', font: 'caption', textTransform: 'uppercase' }}>{nome} - Associado(a) Premium</p>
        </div>
    )
}

const Depoimentos = () => {
    const api = useApi();
    const { departamentos } = useParams<IParams>();
    let tipo_protocolo = 1;

    if (departamentos == 'Eventos') {
        tipo_protocolo = 2;
    }

    const { data } = useQuery('depoimentos', async () => {
        const res = await api.get('ViewDepoimentosParticipantes/consultar/?usuario!=NULL&tipo_protocolo=' + tipo_protocolo + '&order=RAND() LIMIT 21');
        if (res.data.erro) {
            return [];
        }
        return Object.values(res.data);
    });

    console.log(data);

    return (

        <BackGroundDepoimentos style={{ backgroundSize: '100%' }} background={IMAGE_DEPOIMENTO}>
            <Carousel
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                fade
                indicators={false}
                controls={false}
            >
                {data && data.length > 0 && data.map((value: any, key: number) => (
                    <Carousel.Item key={key} interval={(3000)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Depoimento nome={value.nome} usuario={value.usuario} descricao={value.comentario} />
                    </Carousel.Item>
                ))}
            </Carousel>
        </BackGroundDepoimentos>
    )
}

export default Depoimentos;
